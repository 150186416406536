<template>
	<div id="app">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</div>
</template>

<script>
	export default {
		name: 'App',
		mounted() {

		},
		onUnload() {},
		onShow: function() {},
	}
</script>

<style>
	@import url("./assets/css/scrollbar.css");
	@import url("./assets/icon/iconfont.css");

	html,
	body {
		height: 100%;
		/* user-select: none; */
	}
	* {
		margin: 0;
		padding: 0;
	}

	#app {
		/* font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale; */
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
		color: #2c3e50;
		height: 100%;
	}
</style>
