<template>
	<div class="head1">
		<div class="head_L">
			<div class="head_L1">
				<div class="head_L1_L">
					<img src="../../assets/img/logo1.png" alt="Logo" style="width: 100%;height: 100%;">
				</div>
				<div class="head_L1_R">
					冷链验证报告生成系统1.0.0
				</div>
			</div>
		</div>
		<div class="head_R">
			<div class="head_R1" style="display: flex;align-items: center;">
				<span>{{username}}</span>
				<el-dropdown @command="closes">
					<div class="el-dropdown-link">
						<div class="head_R_img">
							<img src="../../assets/img/Touxiang.svg">
						</div>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="0">个人主页</el-dropdown-item>
						<el-dropdown-item command="1">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="head_R1 head_R_All" @click="newSuss">
				<el-tooltip content="新消息" placement="bottom" effect="dark">
					<i class="el-icon-bell" style="font-size: 22px;"></i>
				</el-tooltip>
			</div>
			<div class="head_R1 head_R_All" @click="newSuss">
				<el-tooltip content="更换主题" placement="bottom" effect="dark">
					<i class="el-icon-goods" style="font-size: 22px;"></i>
				</el-tooltip>
			</div>
			<div class="head_R1 head_R_All" @click="keydown">
				<el-tooltip content="全屏显示" placement="bottom" effect="dark">
					<i class="el-icon-full-screen" style="font-size: 22px;"></i>
				</el-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
	import screenfull from 'screenfull'; //全屏
	export default {
		data() {
			return {
				username:this.$store.state.LoginInfo.username
			}
		},
		watch: {
			//路由变化,设置标签
			$route() {
				this.username = this.$store.state.LoginInfo.username
			},
		},
		methods: {
			closes(e) { //退出
				switch (e) {
					case "0":
						this.newSuss()
						break
					case "1":
						this.$store.commit('Login', {
							token: '',
							username: '',
							password: '',
							checked: true
						})
						this.$store.commit('SignOut', [{
							path: '/index',
							name: 'Index',
							title: '工作首页'
						}])
						this.$router.push({
							path: this.$route.query.redirect || '/'
						})
						break
					default:

				}
			},
			newSuss() {
				this.$notify.warning({
					title: '提醒',
					message: '功能正在开发中，请稍等...',
					offset: 100
				});
			},
			keydown() {
				if (!screenfull.enabled) {
					return false;
				}
				screenfull.toggle();
			},
		},
	}
</script>

<style>
	.head1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 70px;
	}
	.head_L1{
		display: flex;
		align-items: center;
		color: #fff;
	}
	.head_L1_L{
		width:35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
	}
	.head_L1_R{
		font-size: 18px;
	}
	.head_R{
		color: #fff;
	}
</style>
