export default {
	MenuList: [{//管理员
			name: '工作台',
			icon: 'el-icon-menu',
			path: '/home',
		},{
			name: '设备分类',
			icon: 'el-icon-s-cooperation',
			path: '/classifyList',
		},
		{
			name: '公司管理',
			icon: 'el-icon-s-order',
			path: '/corpList',
		}
	],
	UserList: [{//用户
			name: '工作台',
			icon: 'el-icon-menu',
			path: '/home',
		},{
			name: '公司账户',
			icon: 'el-icon-s-cooperation',
			path: '/companyAccount',
		}
	]
}
