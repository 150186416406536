<template>
	<div class="layout">
		<div class="layout_Menu">
			<TopMenu/>
		</div>
		<div class="layout_Content">
			<div class="layout_Content_L">
				<Menu :isCollapse="isCollapse" />
			</div>
			<div class="layout_Content_R">
				<Content @changCollapse="changCollapse" />
			</div>
		</div>
	</div>
</template>

<script>
	import TopMenu from './TopMenu.vue'
	import Menu from './Menu.vue'
	import Content from './Content.vue'
	export default {
		components: {
			Menu,
			Content,
			TopMenu,
		},
		data() {
			return {
				isCollapse: true, //收缩左边导航
			}
		},
		mounted() {
		},
		methods: {
			changCollapse() {
				this.isCollapse = !this.isCollapse
			}
		},
	}
</script>

<style>
	.layout {
		background: #fff;
		height: 100%;
		min-width: 1440px;
		display: flex;
		flex-direction: column;
	}

	.layout_Menu {
		padding: 0 20px;
		background: #323c51 !important;
		box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
	}

	.layout_Content {
		flex: 1;
		display: flex;
		overflow: hidden;
	}

	.layout_Content_L {
		background-color: #3d4b61;
	}

	.layout_Content_R {
		flex: 1;
		overflow: hidden;
	}
</style>
