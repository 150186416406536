<template>
	<div class="NavList" :style="isCollapse==false?'width: 160px':''">
		<el-menu background-color="#3d4b61" :default-active="activ" active-text-color="#fff" text-color="#999" style="border-right:none;" class="el-menu-vertical-demo" :collapse="isCollapse" router>
			<el-menu-item :index="item.path" v-for="(item,index) in Menu" :key='index'>
				<i :class="item.icon" class="iconColor"></i>
				<span slot="title">{{item.name}}</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	import Menu from '../../assets/js/Menu.js'
	export default {
		props: ['isCollapse'],
		data() {
			return {
				activ: this.$route.path,
				Menu:[]
			};
		},
		updated() {
			this.Menu = this.$store.state.Loginpermissions==1?Menu.MenuList:Menu.UserList
		},
		watch: {
			//路由变化,设置标签
			$route(newValue) {
				this.activ = newValue.path
			},
		},
		mounted() {
			this.Menu = this.$store.state.Loginpermissions==1?Menu.MenuList:Menu.UserList
		},
		methods: {}
	}
</script>

<style>
	.iconColor{
		font-size: 20px;
		margin-bottom: 3px;
		margin-right:7px;
	}
	.el-menu-item-group__title {
		padding: 0 0 0 20px;
		line-height: normal;
		font-size: 12px;
		color: #fff;
	}
</style>
