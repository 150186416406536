<template>
	<div class="">
		<div style="height: 400px" id="main">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				data1: [2, 3, 6, 1, 5, 1, 3],
				data2: [-1, 7, 3, -2, 5, 3, 1],
			}
		},
		mounted() {
			this.echartsInit()
		},
		methods: {
			//初始化echarts
			echartsInit() {
				//柱形图
				//因为初始化echarts 的时候，需要指定的容器 id='main'
				var myChart = this.$echarts.init(document.getElementById('main'));
				myChart.on('click', function(params) {
					console.log('点击了', params);
					if (params.componentType == "markPoint") {
						console.log('点击了气泡', params.value);
					} else if (params.componentType == "markLine") {
						console.log('点击了线条', params.value);
					}
				});
				myChart.setOption({
					tooltip: {
						trigger: 'axis'
					},
					legend: {},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} °C'
						}
					},
					series: [{
							name: '冷藏车',
							type: 'line',
							itemStyle: {
								normal: {
									label: {
										show: true,
									}
									
								}
							},
							data: this.data1,
							markPoint: {
								// symbol: 'image://' + require('../../../assets/img/LoginBJ.jpg'), // 自定义图片作为标注展示
								symbol: "pin", //气泡样式 ‘circle’, ‘rect’, ‘roundRect’, ‘triangle’, ‘diamond’, ‘pin’, ‘arrow’ 
								symbolSize: 40, //气泡大小
								data: [{
										type: 'normal',
										name: 'Max'
									},
									{
										type: 'min',
										name: 'Min'
									}
								]
							},
							markLine: {
								data: [{
									type: 'average',
									name: 'Avg'
								}]
							}
						},
						{
							name: '库房',
							type: 'line',
							data: this.data2,
							markPoint: {
								data: [{
										type: 'max',
										name: 'Max'
									},
									{
										type: 'min',
										name: 'Min'
									}
								]
							},
							markLine: {
								data: [{
									type: 'average',
									name: 'Avg'
								}]
							}
						}
					] //series
				})
				// 	setInterval(() => {
				// }, 2000);
				window.addEventListener('resize', function() {
					myChart.resize();
				}) //自适应宽度
			},
		}
	}
</script>

<style>
</style>
