<template>
	<div class="Contents">
		<div class="Content">
			<div class="Content1">
				<el-button type="primary" icon="el-icon-s-fold" size='small' @click="changMenu"></el-button>
				<Breadcrumbs></Breadcrumbs>
			</div>
			<div class="Content2">
				<router-view></router-view>
			</div>
			<div class="Content3">
				<footers></footers>
			</div>
		</div>
	</div>
</template>

<script>
	import screenfull from 'screenfull'; //全屏
	import Breadcrumbs from './Breadcrumb.vue';
	import footers from './footer.vue'
	export default {
		components: {
			Breadcrumbs,
			footers
		},
		data() {
			return {
				username: this.$store.state.LoginInfo.username
			}
		},
		methods: {
			closes(e) { //退出
				switch (e) {
					case "0":
						this.newSuss()
						break
					case "1":
						this.$store.commit('Login', {
							token: '',
							username: '',
							password: '',
							checked: true
						})
						this.$store.commit('SignOut', [{
							path: '/index',
							name: 'Index',
							title: '工作首页'
						}])
						this.$router.push({
							path: this.$route.query.redirect || '/'
						})
						break
					default:

				}
			},
			changMenu() {
				this.$emit('changCollapse')
			},
			newSuss() {
				this.$notify.warning({
					title: '提醒',
					message: '功能正在开发中，请稍等...',
					offset: 100
				});
			},
			keydown() {
				if (!screenfull.enabled) {
					return false;
				}
				screenfull.toggle();
			},
		},
	}
</script>

<style>
	.Contents {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.head_R_img {
		border-radius: 50%;
		background: #545c64;
		margin-left: 10px;
	}

	.head {
		box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
		display: flex;
		flex-direction: column;
		z-index: 100;
		padding: 0 20px;
	}

	.head1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 70px;
	}

	.head_L {
		display: flex;
		align-items: center;
	}

	.head_L2 {
		margin: -1px 0 0 20px;
	}

	.head_R {
		display: flex;
		align-items: center;
	}

	.head_R1 {
		margin-left: 30px;
	}

	.head_R_All {}

	.head_R_img {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.head_R_img>img {
		max-width: 80%;
		max-height: 80%;
	}

	.Content {
		padding: 15px;
		flex: 1;
		background-color: #f2f2f2;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.Content1 {
		background: #fff;
		display: flex;
		align-items: center;
		padding: 15px 20px;
		margin-bottom: 15px;
		border-radius: 5px;
	}

	.Content2 {
		flex: 1;
		border-radius: 5px;
		overflow-y: scroll;
		background: #fff;
	}

	.Content3 {
		background: #fff;
		margin-top: 15px;
		padding: 10px 0;
		border-radius: 5px;
	}
</style>
