import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			key: 'stort',
			paths: ['LoginInfo', 'CorpData', 'T_task_id', 'innerDrawerID', 'Loginpermissions',
				"ReportList"
			] //, 'Tags'
		})
	],
	state: { //保存数据
		LoginInfo: {
			username: '',
			password: '',
			token: '',
			checked: false
		},
		Tags: [{
			path: '/index',
			name: 'Index',
			title: '工作首页'
		}],
		CorpData: {},
		T_task_id: {},
		innerDrawerID: {},
		Loginpermissions: null, //登录权限（1管理员2用户）
		ReportList: [],
	},
	mutations: { //触发事件，相当于方法 
		permiss(state, data) { //参数的话，第二个参数就是用户传入的值，然后在方法中赋值给state中的变量
			console.log('权限', data)
			state.Loginpermissions = data
		},
		// 保存当前菜单栏的路径
		Login(state, Login) { //参数的话，第二个参数就是用户传入的值，然后在方法中赋值给state中的变量
			state.LoginInfo.username = Login.username
			state.LoginInfo.password = Login.password
			state.LoginInfo.token = Login.token
			state.LoginInfo.checked = Login.checked
		},
		SignOut(state, data) { //退出登陆
			state.Tags = data
		},
		CorpList(state, data) { //存储/User/List接口的uuid
			state.CorpData = data
		},
		T_taskID(state, data) { //存储/User/List接口的uuid
			state.T_task_id = data
		},
		SetinnerDrawerID(state, data) {
			state.innerDrawerID = data
		},
		Tags(state, Tag) {
			var Arr = state.Tags
			console.log(Arr, Tag)
			if (Arr.length === 0) {
				state.Tags.push(Tag)
			} else {
				var ArrBl = Arr.some((val) => {
					return val.path.includes(Tag.path)
				})
				if (ArrBl) { //路由存在
					return
				} else { //路由不存在
					state.Tags.push(Tag)
				}
			}
		},
		ReportGenData(state, data) { //存储报告生成界面的上次填写数据
			// state.ReportList  T_VerifyTemplate_id
			var Arr = state.ReportList
			if (data.length === 0) { //store点击了清空数据
				state.ReportList = []
			}
			if (Arr.length === 0) {
				state.ReportList.push(data)
			} else {
				const b = Arr.some(item => item.T_VerifyTemplate_id === data
					.T_VerifyTemplate_id) //如果id相等返回 为true则存在这个数据
				if (b) { //查看是否已经存在  存在,替换
					Arr.forEach(function(obj, index) {
						if (obj.T_VerifyTemplate_id === data.T_VerifyTemplate_id) {
							state.ReportList.splice(index, 1, data)
						}
					})
				} else { //不存在，直接push添加
					state.ReportList.push(data)
				}
			}
		},
		delTags(state, Tag) {
			var Arr = state.Tags
			console.log('删除', Tag)
			Arr.some((val, index) => {
				if (val.path.includes(Tag)) {
					state.Tags.splice(index, 1)
					return
				}
			})
		}
	}
})
