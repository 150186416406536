<template>
	<div class="HomeList">
		<div class="HomeList1">
			<div class="HomeList1_0 HomeList1_1">
				<el-progress type="circle" :percentage="percentage1" color="#f56c6c"></el-progress>
				<div style="margin-top: 10px;">待完善1/15</div>
			</div>
			<div class="HomeList1_0 HomeList1_2">
				<el-progress type="circle" :percentage="percentage2" color="#e6a23c"></el-progress>
				<div style="margin-top: 10px;">待完善4/5</div>
			</div>
			<div class="HomeList1_0 HomeList1_3">
				<el-progress type="circle" :percentage="percentage3" color="#5cb87a"></el-progress>
				<div style="margin-top: 10px;">待完善4/5</div>
			</div>
			<div class="HomeList1_0 HomeList1_4">
				<el-progress type="dashboard" :percentage="percentage4" color="#1989fa"></el-progress>
				<div style="margin-top: 10px;">待完善4/5</div>
			</div>
		</div>
		<div class="HomeList3">
			<div class="typing">
				贵州宝智达冷链科技有限公司欢迎你！
			</div>
		</div>
		<div class="HomeList2">
			<div class="HomeList2_echarts">
				<echarts1></echarts1>
			</div>
		</div>
	</div>
</template>

<script>
	import echarts1 from './modules/echarts1.vue'
	import $ from 'jquery'
	export default {
		components: {
			echarts1
		},
		data() {
			return {
				percentage1: 0,
				percentage2: 0,
				percentage3: 0,
				percentage4: 0,
			};
		},
		created() { //进入界面执行定时器
			this.myVar = setInterval(() => {
				this.percentage1 = this.random()
				this.percentage2 = this.random()
				this.percentage3 = this.random()
				this.percentage4 = this.random()
			}, 3000)
		},
		destroyed() { //离开界面断开定时器
			clearInterval(this.myVar)
		},
		mounted() {
			// $.ajax({
			// 	type: 'POST',
			// 	url: 'https://coldverify.baozhida.cn/API/Login_Admin_verification', //请求的URL地址
			// 	timeout: 2000,
			// 	headers:{
			// 		'Timeout': 2000,
			// 		'Comtent-Type':'application/x-www-from-urlencoded'
			// 	},
			// 	data: {}, 
			// 	success: function(res) {
			// 		console.log('home',res)
			// 	}
			// })
			this.percentage1 = this.random()
			this.percentage2 = this.random()
			this.percentage3 = this.random()
			this.percentage4 = this.random()
		},
		methods: {
			random() {
				return Math.floor(Math.random() * (100 - 1)) + 1;
			}
		}
	}
</script>

<style>
	.HomeList {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.HomeList1 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 15px;
	}

	.HomeList1_0 {
		height: 200px;
		border-radius: 5px;
		padding: 10px;
		color: #999;
		font-size: 12px;
		font-weight: 500;
		background: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.HomeList2 {
		min-height: 450px;
		margin-top: 15px;
		border-radius: 5px;
		background: #fff;
		display: flex;
		align-items: center;
	}

	.HomeList2_echarts {
		width: 100%;
	}

	.HomeList3 {
		flex: 1;
		min-height: 250px;
		font-size: 30px;
		margin-top: 15px;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
	}

	.typing {
		white-space: nowrap;
		overflow: hidden;
		/* animation: typing 3.5s steps(16, end), blink-caret .75s ease infinite; */
	}

	/* 打印效果 */
	@keyframes typing {
		from {
			width: 0;
		}

		to {
			width: 16em;
		}
	}

	/* 光标闪啊闪 */
	@keyframes blink-caret {

		from,
		to {
			box-shadow: 1px 0 0 0 transparent;
		}

		50% {
			box-shadow: 1px 0 0 0;
		}
	}
</style>
