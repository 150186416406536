import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue' //布局页
import Home from '@/views/Home/Home.vue' //首页先
import stores from '../store/store.js'
Vue.use(VueRouter)
// 通用页面, 这里的配置不需要权限
const routes = [{
		path: '',
		name: 'Login',
		component: () => import('@/views/Login/Login.vue')
	},
	{
		path: '/index',
		name: 'index',
		component: Layout,
		hidden: true, //导航菜单忽略选项
		redirect: '/home',
		meta: { //路由元信息
			isLogin: true,
			title: '工作台',
		},
		children: [{
				path: '/home',
				name: 'Home',
				component: Home,
				meta: {
					title: '工作主页',
					hideclose: true, //做tag的保留首页
				},
			},
			{
				path: '/companyAccount',
				name: 'CompanyAccount',
				// redirect: '/facilityBook',
				component: () => import('@/views/CompanyAccount/index.vue'),
				meta: {
					title: '公司账户',
				},
				// children: [ //用户管理
				// 	{
				// 		path: '/facilityBook',
				// 		name: 'FacilityBook',
				// 		component: () => import('@/views/CompanyAccount/modules/FacilityBook.vue'),
				// 		meta: {
				// 			title: '报告任务列表'
				// 		},
				// 	},
				// 	{
				// 		path: '/facilityData',
				// 		name: 'FacilityData',
				// 		component: () => import('@/views/CompanyAccount/modules/FacilityData.vue'),
				// 		meta: {
				// 			title: '报告任务列表'
				// 		},
				// 	},
				// ]
			},
			{
				path: '/deviceClassify', //项目管理--------------
				name: 'DeviceClassify',
				redirect: '/classifyList',
				component: () => import('@/views/DeviceClassify/index.vue'),
				meta: {
					title: '设备分类'
				},
				children: [ //用户管理
					{
						path: '/classifyList',
						name: 'ClassifyList',
						component: () => import('@/views/DeviceClassify/modules/ClassifyList.vue'),
						meta: {
							title: '分类列表'
						},
					},
					{
						path: '/facilityList',
						name: 'FacilityList',
						component: () => import('@/views/DeviceClassify/modules/FacilityList.vue'),
						meta: {
							title: '设备列表'
						},
					}
				]
			},
			{
				path: '/corpManage', //项目管理--------------
				name: 'CorpManage',
				redirect: '/corpList',
				component: () => import('@/views/CorpManage/index.vue'),
				meta: {
					title: '公司管理'
				},
				children: [ //用户管理
					{
						path: '/corpList',
						name: 'CorpList',
						component: () => import('@/views/CorpManage/modules/CorpList.vue'),
						meta: {
							title: '公司管理'
						},
					},
					{
						path: '/corpManagement',
						name: 'CorpManagement',
						redirect: '/corpManageIndex',
						component: () => import('@/views/CorpManage/modules/CorpManagement.vue'),
						meta: {
							title: '任务管理'
						},
						children: [{
								path: '/corpManageIndex',
								name: 'CorpManageIndex',
								component: () => import(
									'@/views/CorpManage/modules/modules/CorpManageIndex.vue'),
								meta: {
									title: '任务管理'
								},
							},
							{
								path: '/dataSources',
								name: 'DataSources',
								component: () => import(
									'@/views/CorpManage/modules/modules/DataSources.vue'),
								meta: {
									title: '数据来源'
								},
							},
							{
								path: '/dataEditing',
								name: 'DataEditing',
								component: () => import(
									'@/views/CorpManage/modules/modules/DataEditing.vue'),
								meta: {
									title: '数据编辑'
								},
							},
							{
								path: '/dataCheck',
								name: 'DataCheck',
								component: () => import(
									'@/views/CorpManage/modules/modules/DataCheck.vue'),
								meta: {
									title: '数据校验'
								},
							},
							{
								path: '/reportGenerate',
								name: 'ReportGenerate',
								component: () => import(
									'@/views/CorpManage/modules/modules/ReportGenerate.vue'),
								meta: {
									title: '报告生成'
								},
							},
							{
								path: '/reportEditing',
								name: 'ReportEditing',
								component: () => import(
									'@/views/CorpManage/modules/modules/ReportEditing.vue'),
								meta: {
									title: '报告编辑'
								},
							}
						]
					},
				]
			},
		]
	},
	{
		path: '*',
		component: () => import('@/components/404.vue'),
	},
]
const router = new VueRouter({
	routes
	// mode: 'history'
})
//登录拦截//未登录返回到登录界面
router.beforeEach((to, from, next) => {
	var token = stores.state.LoginInfo.token
	if (token && to.name == "Login") { //防止登录之后url到登录界面
		next('/index')
	}
	if (to.matched.some(ele => ele.meta.isLogin)) { //meta放在了layout里面，她下面的所有子组件都需要登录，判断是否需要登录
		if (token) { //存在token进入界面
			next()
		} else { //跳到login
			next('/')
		}
	} else {
		next()
	}
})

export default router
