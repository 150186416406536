import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index.js';
import store from './store/store.js';
import * as echarts from 'echarts'
import * as XLSX from 'xlsx'
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)
Vue.prototype.XLSX = XLSX
Vue.prototype.$echarts=echarts
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
import App from './App.vue'
Vue.use(ElementUI);
Vue.use(echarts)
Vue.config.productionTip = false

new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')
