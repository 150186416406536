<template>
	<div style="display: flex;align-items: center;margin-left: 30px;">
		<i class="el-icon-folder-opened" style="margin:0px 10px 5px 0;font-size: 20px;"></i>
		<div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item v-for="(item,index) in BreadList" :key="index" :to="item.path">{{item.meta.title}}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				BreadList: this.$route.matched,
			}
		},
		watch: {
			$route(to) {
				this.BreadList = to.matched
			}
		},
		mounted() {

		},
	}
</script>

<style>
</style>
